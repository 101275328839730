import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import { GatewayId } from '../../../site-detail/models/Gateway';
import { SitesPageActions } from '../../../site-list/store/actions/sites';
import { HttpToolboxGateway } from '../../services/http-toolbox-gateway.service';
import { ToolboxActions } from '../actions/toolbox';

@Injectable()
export class ToolboxEffects {
    loadToolboxesOnPageOpened$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesPageActions.opened, AppActions.initialized),
            exhaustMap(() =>
                this.toolboxService
                    .getToolboxes()
                    .pipe(map((toolboxes: GatewayId[]) => ToolboxActions.loaded({ toolboxes }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private toolboxService: HttpToolboxGateway,
    ) {}
}
