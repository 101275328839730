{
  "name": "serv-e-go-frontend",
  "version": "3.0.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=remoteAPI --host 0.0.0.0 --port 4200 --disable-host-check ",
    "watch": "ng serve --configuration=local",
    "build": "ng build --output-hashing=bundles --configuration production --stats-json",
    "build:local": "ng build --output-hashing=bundles --configuration=local",
    "lint": "ng lint",
    "prettier": "prettier --write ./src/app/**/*.ts",
    "postbuild": "node ./postbuild/index.js",
    "checkTrad": "node tools/i18n/findMissingKeys.js",
    "update-chrome-webdriver": "./node_modules/webdriver-manager/bin/webdriver-manager update --versions.chrome=$(google-chrome --version | cut -d ' ' -f 3)",
    "test": "ng test --watch=false --browsers=ChromeHeadlessCI --source-map",
    "test:watch": "ng test --watch=true --browsers=ChromeHeadlessCI --source-map",
    "test:coverage": "npm run test -- --code-coverage",
    "test:ihm": "npm run build:local && npm run test:ihm:linux",
    "test:ihm:linux": "docker compose -f tests/visual-regression-tests/docker/docker-compose.yml run servego-playwright npx playwright test",
    "test:ihm-build-docker-image": "docker build -t servego-playwright tests/visual-regression-tests/docker/",
    "test:ihm-report": "npx playwright show-report",
    "test:ihm-clean": "find . -type d -name '*-snapshots' -exec sh -c 'rm -f \"$0\"/*.png' {} \\;",
    "test:ihm-update-snapshots": "npm run test:ihm-clean && npm run build:local && npm run test:ihm:linux -- --update-snapshots",
    "docker-build": "docker build -f dockerfiles/Dockerfile --target=server .",
    "bundle-report": "webpack-bundle-analyzer dist/stats.json",
    "outdated": "npx --yes -- check-outdated --ignore-pre-releases --columns name,current,latest,changes --types minor,patch,reverted",
    "prepare": "husky"
  },
  "engines": {
    "npm": "^11.1.0"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/core": "19.2.6",
    "@angular/animations": "19.2.5",
    "@angular/cdk": "19.2.8",
    "@angular/common": "19.2.5",
    "@angular/compiler": "19.2.5",
    "@angular/core": "19.2.5",
    "@angular/forms": "19.2.5",
    "@angular/material": "19.2.8",
    "@angular/material-luxon-adapter": "19.2.8",
    "@angular/platform-browser": "19.2.5",
    "@angular/platform-browser-dynamic": "19.2.5",
    "@angular/router": "19.2.5",
    "@daily-co/daily-js": "0.77.0",
    "@ngrx/effects": "19.1.0",
    "@ngrx/router-store": "19.1.0",
    "@ngrx/store": "19.1.0",
    "@ngx-translate/core": "16.0.4",
    "@ngx-translate/http-loader": "16.0.1",
    "@sentry/angular": "9.11.0",
    "angular-auth-oidc-client": "19.0.0",
    "bootstrap": "5.3.5",
    "chart.js": "4.4.8",
    "ci": "^2.3.0",
    "core-js": "3.41.0",
    "flat": "^6.0.1",
    "jsonpath-plus": "10.3.0",
    "luxon": "3.6.1",
    "ng2-charts": "8.0.0",
    "ngx-json-viewer": "3.2.1",
    "ngx-mask": "19.0.6",
    "rxjs": "7.8.2",
    "scroll-into-view-if-needed": "^3.1.0",
    "socket.io-client": "4.8.1",
    "tslib": "2.8.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.6",
    "@angular-eslint/builder": "19.3.0",
    "@angular-eslint/eslint-plugin": "19.3.0",
    "@angular-eslint/eslint-plugin-template": "19.3.0",
    "@angular-eslint/schematics": "19.3.0",
    "@angular-eslint/template-parser": "19.3.0",
    "@angular/cli": "19.2.6",
    "@angular/compiler-cli": "19.2.5",
    "@angular/language-service": "19.2.5",
    "@ngrx/schematics": "19.1.0",
    "@ngrx/store-devtools": "19.1.0",
    "@playwright/test": "1.51.1",
    "@types/jasmine": "5.1.7",
    "@types/jasminewd2": "2.0.13",
    "@types/luxon": "3.6.2",
    "@types/node": "22.14.0",
    "eslint": "8.57.1",
    "http-server": "14.1.1",
    "husky": "9.1.7",
    "jasmine-core": "5.6.0",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-cli": "2.0.0",
    "karma-coverage": "2.2.1",
    "karma-firefox-launcher": "2.1.3",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "karma-junit-reporter": "2.0.1",
    "lint-staged": "15.5.0",
    "poeditorial": "1.3.0",
    "prettier": "3.5.3",
    "socket.io-mock": "1.3.2",
    "ts-node": "10.9.2",
    "typescript": "5.8.3",
    "typescript-eslint": "8.29.0",
    "webdriver-manager": "12.1.9",
    "webpack-bundle-analyzer": "4.10.2"
  },
  "lint-staged": {
    "src/{app,environments,styles}/**/*.{ts,scss,css,md}": "prettier --write"
  }
}
