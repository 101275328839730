import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class HttpStatsService {
    constructor(private httpService: HttpService) {}

    updateSitesStats(count: number): Observable<void> {
        return this.httpService.post(`/stats`, {
            sites: count,
            sites_servego: count,
        });
    }
}
