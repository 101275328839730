import { LogLevel, PassedInitialConfig } from 'angular-auth-oidc-client';
import ENV_NAMES from '../../environments/ENV_NAMES';
import { AppConfig } from '../AppConfig';

export function getAuthConfig(config: AppConfig): PassedInitialConfig {
    const logLevel = [ENV_NAMES.PROD, ENV_NAMES.PREPROD].includes(config.name) ? LogLevel.Error : LogLevel.Warn;

    return {
        config: {
            authority: config.oauth2.url,
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: config.sso.logoutUrl,
            clientId: config.oauth2.clientId,
            scope: 'openid profile email offline_access',
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true,
            renewTimeBeforeTokenExpiresInSeconds: 30,
            ignoreNonceAfterRefresh: true,
            logLevel: logLevel,
            autoCleanStateAfterAuthentication: false,
        },
    };
}
