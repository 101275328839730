import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import {
    ToasterJoinVideoCallMessage,
    ToasterMessageType,
    ToasterVideoCallInvitationSentMessage,
} from '../../../shared/models/Toaster';
import { StoreService } from '../../../shared/services/store.service';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { AppState } from '../../../shared/store/app-state';
import { getSiteOwner } from '../../../site-detail/store/selectors/site';
import { getOwnerFullName } from '../../../site-detail/utils/owner.util';
import { VideoCall } from '../../models/video-call';
import { ResendVideoCallInvitationUseCase } from '../../services/ResendVideoCallInvitationUseCase';
import { StartVideoCallUseCase } from '../../services/StartVideoCallUseCase';
import { StopVideoCallUseCase } from '../../services/StopVideoCallUseCase';
import { VideoCallModal } from '../../services/video-call-modal';
import { VideoCallGateway } from '../../services/VideoCallGateway';
import { VideoCallProvider } from '../../services/VideoCallProvider';
import { getVideoCallToasterId } from '../../utils/getVideoCallToasterId';
import { AlertResolutionPageActions, InstallationPageActions, VideoCallsActions } from '../actions/videoCall';

@Injectable()
export class VideoCallEffects {
    loadCurrentVideoCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initialized),
            switchMap(() =>
                this.videoCallGateway
                    .getVideoCalls()
                    .pipe(map((videoCalls: VideoCall[]) => VideoCallsActions.videoCallsLoaded({ videoCalls }))),
            ),
        ),
    );

    startVideoCallRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InstallationPageActions.startVideoCallRequested, AlertResolutionPageActions.startVideoCallRequested),
            switchMap(({ siteId }) =>
                this.startVideoCallUseCase
                    .exec(siteId)
                    .pipe(map((videoCall: VideoCall) => VideoCallsActions.videoCallCreated({ siteId, videoCall }))),
            ),
        ),
    );

    sendVideoCallInvitationRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.sendVideoCallInvitationRequested),
            switchMap(({ videoCallId, siteId }) =>
                this.resendVideoCallInvitationUseCase
                    .exec(videoCallId)
                    .pipe(map((videoCall) => VideoCallsActions.videoCallInvitationSent({ siteId, videoCall }))),
            ),
        ),
    );

    showToasterMessageOnInvitationSent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.videoCallCreated, VideoCallsActions.videoCallInvitationSent),
            switchMap(({ siteId }) =>
                this.storeService.select(getSiteOwner(siteId)).pipe(
                    map((siteOwner) => {
                        const toasterMessage: ToasterVideoCallInvitationSentMessage = {
                            id: crypto.randomUUID(),
                            type: ToasterMessageType.VIDEO_CALL_INVITATION_SENT,
                            content: {
                                siteId: siteId,
                                userSiteName: getOwnerFullName(siteOwner),
                                message: 'TOASTER_VIDEO_CALL_INVITATION_SENT_MESSAGE',
                            },
                        };
                        return addToasterMessage({ message: toasterMessage });
                    }),
                ),
            ),
        ),
    );

    showToasterMessageOnParticipantJoined$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.videoCallParticipantJoined),
            filter(({ isOwner }) => !isOwner),
            map(({ siteId, userName }) => {
                const joinVideoCallMessage: ToasterJoinVideoCallMessage = {
                    id: getVideoCallToasterId(siteId),
                    content: {
                        siteId,
                        userName,
                    },
                    type: ToasterMessageType.GUEST_JOINED_VIDEO_CALL,
                };
                return addToasterMessage({
                    message: joinVideoCallMessage,
                });
            }),
        ),
    );

    closeVideoCallAfterRequested$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsActions.videoCallStoppedRequested),
                mergeMap(({ siteId }) =>
                    from(this.videoCallProvider.leaveVideoCall()).pipe(
                        tap(() => {
                            this.stopVideoCallUseCase.exec(siteId);
                            this.videoCallModal.close();
                        }),
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        @Inject('VideoCallGateway') private videoCallGateway: VideoCallGateway,
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
        private readonly startVideoCallUseCase: StartVideoCallUseCase,
        private readonly resendVideoCallInvitationUseCase: ResendVideoCallInvitationUseCase,
        private stopVideoCallUseCase: StopVideoCallUseCase,
        private storeService: StoreService<AppState>,
        private videoCallModal: VideoCallModal,
    ) {}
}
