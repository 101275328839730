import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { SiteService } from '../../../site-detail/services/site.service';
import { loadSiteInfo } from '../../../site-detail/store/actions/site';
import { getOwnerFullName } from '../../../site-detail/utils/owner.util';
import { ToasterMessageType, ToasterServegoChangedMessage } from '../../models/Toaster';
import { UISocketDataMessage } from '../../models/UISocket';
import { addToasterMessage } from '../../store/actions/addToasterMessage';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketServegoAccessGrantedMessage extends UISocketDataMessage {
    isServego: boolean;
    siteId: SiteId;
}

@Injectable()
export class ServegoAccessGrantedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'servego_access_granted';

    constructor(
        private store: Store<AppState>,
        private siteService: SiteService,
    ) {}

    handle(message: UISocketServegoAccessGrantedMessage): void {
        this.siteService.getSite(message.siteId).subscribe(async (site: Site) => {
            this.displayToasterForServegoAccess(site, message);
            this.store.dispatch(loadSiteInfo({ site }));
        });
    }

    private displayToasterForServegoAccess(site: Site, message: UISocketServegoAccessGrantedMessage): void {
        const accessGrantedServegoMessage: ToasterServegoChangedMessage = {
            id: crypto.randomUUID(),
            content: {
                siteId: site.id,
                hasAccess: message.isServego,
                userSiteName: getOwnerFullName(site.owner),
            },
            type: ToasterMessageType.SERVEGO_ACCESS_CHANGED,
        };
        this.store.dispatch(addToasterMessage({ message: accessGrantedServegoMessage }));
    }
}
