import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import { StoreService } from '../../../shared/services/store.service';
import { AppState } from '../../../shared/store/app-state';
import { SitesList } from '../../models/sites';
import { HttpSitesGateway } from '../../services/http-sites-gateway.service';
import { HttpStatsService } from '../../services/http-stats.service';
import { SitesActions, SitesPageActions } from '../actions/sites';
import { getUISiteFilters } from '../selectors/ui';

@Injectable()
export class SitesEffects {
    loadSitesOnPageOpened$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesPageActions.opened, AppActions.initialized),
            exhaustMap(() => this.store.select(getUISiteFilters)),
            exhaustMap(({ keywords, searchToken }) =>
                this.sitesService.getSites({ keywords, searchToken }).pipe(
                    tap(({ count }) => {
                        if (keywords.length === 0) {
                            this.statsService.updateSitesStats(count);
                        }
                    }),
                    map((sitesList: SitesList) => SitesActions.sitesLoaded({ keywords, ...sitesList })),
                ),
            ),
        ),
    );

    onSitesSearched$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.sitesSearched),
            switchMap(({ keywords, searchToken }) =>
                this.sitesService
                    .getSites({ keywords, searchToken })
                    .pipe(map((sitesList: SitesList) => SitesActions.sitesLoaded({ keywords, ...sitesList }))),
            ),
        ),
    );

    fetchMoreSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.loadSitesRequested),
            switchMap(({ filters }) =>
                this.sitesService
                    .getSites(filters)
                    .pipe(
                        map((sitesList: SitesList) =>
                            SitesActions.sitesLoaded({ keywords: filters.keywords, ...sitesList }),
                        ),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private sitesService: HttpSitesGateway,
        private store: StoreService<AppState>,
        private statsService: HttpStatsService,
    ) {}
}
