import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { GatewayId } from '../../site-detail/models/Gateway';
import { Toolbox } from '../models/Toolbox';
import { ToolboxGateway } from './ToolboxGateway';

@Injectable()
export class HttpToolboxGateway implements ToolboxGateway {
    constructor(private httpService: HttpService) {}

    getToolboxes(): Observable<GatewayId[]> {
        return this.httpService
            .get(`/toolbox`)
            .pipe(map((toolboxes: Toolbox[]) => toolboxes?.map(({ gatewayId }) => gatewayId)));
    }
}
