import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { ToasterMessage, ToasterMessageType } from '../../../shared/models/Toaster';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { AppState } from '../../../shared/store/app-state';
import { isToolbox } from '../../../toolbox/store/selectors/toolbox';
import { SitePageActions } from '../actions/site';

@Injectable()
export class SiteDetailNavigationEffect {
    redirectIfIsToolbox$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SitePageActions.opened),
                switchMap(({ siteId }) => this.store.select(isToolbox(siteId))),
                filter(Boolean),
                debounceTime(100),
                tap(() => {
                    this.store.dispatch(
                        addToasterMessage({
                            message: this.redirectMessage('REDIRECTION_TOOL_SITE'),
                            duration: 5000,
                        }),
                    );
                    this.router.navigate(['/sites']);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    redirectMessage = (errorMessage: string): ToasterMessage => ({
        id: crypto.randomUUID(),
        type: ToasterMessageType.ERROR,
        content: {
            errorMessage,
        },
    });
}
