import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { SiteService } from '../../../site-detail/services/site.service';
import { removeSite } from '../../../site-detail/store/actions/site';
import { SitesPageActions } from '../../../site-list/store/actions/sites';
import { FavoriteSitesActions } from '../actions/favorite-site';
import { FavoriteSiteService } from '../api/favorite-site.service';
import { getFavoriteBySiteId } from '../selectors/favorite-site';

@Injectable()
export class FavoriteSitesEffects {
    loadFavoritesOnPageOpened$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesPageActions.opened, FavoriteSitesActions.reloadRequested),
            exhaustMap(() => this.favoriteSitesService.getFavoritesSites()),
            map((favorites) => FavoriteSitesActions.favoritesLoaded({ favorites })),
        ),
    );

    onFavoriteSitesLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FavoriteSitesActions.favoritesLoaded),
            map(({ favorites }) => favorites.map(({ resourceOID }) => resourceOID)),
            filter((siteOIDs) => siteOIDs.length > 0),
            switchMap((siteOIDs) => this.sitesService.getSitesBatch(siteOIDs)),
            map((sites) => FavoriteSitesActions.sitesLoaded({ sites })),
        ),
    );

    removeFavoriteIfSiteHasBeenDeleted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(removeSite),
                switchMap(({ siteId }) => this.store.select(getFavoriteBySiteId(siteId))),
                filter(Boolean),
                tap(({ favoriteOID }) => this.favoriteSitesService.removeFavorite(favoriteOID)),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private sitesService: SiteService,
        private favoriteSitesService: FavoriteSiteService,
    ) {}
}
