import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import type { Intervention } from '../../../interventions/models/Intervention';
import { InterventionService } from '../../../interventions/services/intervention.service';
import { InterventionActions } from '../../../interventions/store/actions/interventions';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { getSiteById } from '../../../site-detail/store/selectors/site';
import { getOwnerFullName } from '../../../site-detail/utils/owner.util';
import { now } from '../../../utils/date';
import { ToasterAccessGrantedMessage, ToasterMessageType } from '../../models/Toaster';
import { UISocketDataMessage } from '../../models/UISocket';
import { addToasterMessage } from '../../store/actions/addToasterMessage';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketInterventionAccessGrantedMessage extends UISocketDataMessage {
    interventionSessionId: string;
    siteId: SiteId;
}

@Injectable()
export class InterventionGrantedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'intervention_access_granted';

    constructor(
        private interventionService: InterventionService,
        private store: Store<AppState>,
    ) {}

    handle(message: UISocketInterventionAccessGrantedMessage): void {
        this.interventionService.getInterventions(message.siteId).subscribe((interventions) => {
            this.store.dispatch(InterventionActions.loadSiteInterventions({ interventions }));

            const intervention: Intervention = interventions.find((i) => i.sessionId === message.interventionSessionId);
            if (!intervention) {
                return;
            }

            this.store
                .select(getSiteById(message.siteId))
                .pipe(take(1), filter(Boolean))
                .subscribe((site: Site) => {
                    const accessGrantedInterventionMessage: ToasterAccessGrantedMessage = {
                        id: crypto.randomUUID(),
                        content: {
                            accessGrantedTime: now(),
                            siteId: site.id,
                            userSiteName: getOwnerFullName(site.owner),
                            intervention: intervention,
                        },
                        type: ToasterMessageType.ACCESS_GRANTED,
                    };

                    this.store.dispatch(
                        addToasterMessage({
                            message: accessGrantedInterventionMessage,
                        }),
                    );
                });
        });
    }
}
